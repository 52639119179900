import 'lazysizes';
import './src/styles/global.css';
import './src/styles/prettier.css';
import './src/styles/transition.css';
import './src/styles/whitelist.css';
import { MAIN_BLOG_PATHNAME } from './src/utils/enums';

const bannerHeightChromeMobile = () => {
  //TODO: Not using it now but req later
  // if (window.innerWidth <= WIDTH_MOBILE_BREAKPOINT) {
  //   if (document.querySelector('.banner-home-height')) {
  //     document.querySelector('.banner-home-height').style.height = `${
  //       window.innerHeight - 64 - (window.innerWidth < 420 ? 161 : 138)
  //     }px`;
  //   } else if (document.querySelector('.banner-height')) {
  //     document.querySelector('.banner-height').style.height = `${
  //       window.innerHeight - 64
  //     }px`;
  //   }
  // }
};

let tempY = 0;

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  if (!prevRouterProps) return;
  const { location: prevLocation } = prevRouterProps;
  const { pathname } = location;
  const { pathname: prevPathname } = prevLocation;
  if (
    prevPathname &&
    (MAIN_BLOG_PATHNAME.includes(pathname) || pathname.includes('/blog/tag')) &&
    (MAIN_BLOG_PATHNAME.includes(prevPathname) ||
      prevPathname.includes('/blog/tag'))
  ) {
    window.scrollTo(0, getSavedScrollPosition(prevLocation)[1] || tempY);
    tempY = getSavedScrollPosition(prevLocation)[1] || tempY;
  } else tempY = 0;

  return false;
};

export const onRouteUpdate = () => {
  bannerHeightChromeMobile();
};

export const onClientEntry = () => {
  setTimeout(() => bannerHeightChromeMobile(), 1000);
};

export const onInitialClientRender = () => {
  setTimeout(() => bannerHeightChromeMobile(), 1000);
};
