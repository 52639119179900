const NEWLY_OPENED_POSITION = 'new';
const RECENTLY_OPENED_POSITION = 'active';
const OLD_POSITION = 'critical';

const TESTIMONIAL_IMAGE = [
  { slug: 'Ashish Dhawan', image: '/ashish-dhawan.png' },
  { slug: 'Kailash Nadh', image: '/kailash-nadh.jpg' },
];

const BOARD_IMAGES = [
  { slug: 'CatharinaEklof', image: '/catharina-eklof.png' },
  { slug: 'KailashNadh', image: '/kailash-nadh-advisor.jpg' },
  { slug: 'Dr.SagnikDey', image: '/sagnik-dey.png' },
  { slug: 'YagnikKhanna', image: '/yagnik-khanna.jpg' },
];

const TRANSITION_CONFIG = {
  direction: 'up',
  hex: '#0A61F7',
  duration: 1,
  fade: true,
};

const WIDTH_MOBILE_BREAKPOINT = 768;
const WIDTH_TAB_BREAKPOINT = 1024;

const MAIN_BLOG_PATHNAME = ['/blog', '/blog/'];

const ICON_COLOR = {
  dark: 'icon-black',
  light: 'icon-white',
  brand: '',
};

const BLOG_VISIBILITY = {
  public: 'public',
  members: 'members',
};

const ONLINE_CATALOGUE = 'Online';
const ONLINE_CATALOGUE_ONLY_ON_ST = 'Only In ST';

const SPACETIME_URL = process.env.GATSBY_SPACETIME_URL;
const DEV_PORTAL_URL = process.env.GATSBY_DEV_PORTAL_URL;

const ENVIRONMENT = process.env.GATSBY_ENV;

const DEVELOPMENT = 'development';
const PRODUCTION = 'production';

const COLORS = ['bg-red', 'bg-yellow', 'bg-blue', 'bg-cyan'];

const TEMP_INT_LIVE_PAGES = [
  'ghg-emissions-fires',
  'spatial-aq',
  'bam-aq',
  'power-plants',
  'water-quantification',
  'fire-predictions',
  'carbon-sequestration',
];

const HERO_HOSTSPOT_SLUGS = {
  'map-viz': { top: 33.4, left: 33.62 },
  search: { top: 20.04, left: 17.7 },
  temporal: { top: 60, right: 21 },
  'dataset-selector': { top: 9.98, left: 21.64 },
  'color-legend': { bottom: 12.8, left: 25 },
  'info-box': { top: 9.98, left: 32 },
  'user-info': { top: 9.98, right: 15 },
  'colour-tool': { bottom: 15.5, left: 9 },
  basemap: { bottom: 15.5, left: 16 },
  embed: { top: 21, right: 12 },
  timelapse: { bottom: 8, right: 35.8 },
  'gif-exporter': { bottom: 8, right: 32 },
  'time-bucket': { bottom: 8, right: 26 },
  'duration-selector': { bottom: 8, right: 15 },
  'custom-duration': { bottom: 8, right: 11 },
};

const EMBED_EDITOR_OPTIONS = {
  minimap: {
    enabled: false,
  },
  scrollbar: {
    vertical: 'hidden',
    horizontal: 'hidden',
    handleMouseWheel: false,
  },
  readOnly: true,
  domReadOnly: true,
  wordWrap: true,
  folding: false,
  fontWeight: 700,
  fontSize: '16px',
  lineNumbers: 'off',
  glyphMargin: false,
  automaticLayout: true,
  lineNumbersMinChars: 0,
  lineDecorationsWidth: 0,
  renderSideBySide: false,
};

const EMBED_CUSTOM_THEME_RULES = {
  base: 'vs-dark',
  inherit: true,
  rules: [
    {
      foreground: '#FB9926',
      token: 'attribute.value.html',
    },
    {
      foreground: '#227DE8',
      token: 'attribute.name.html',
    },
    {
      foreground: '#11BFF5',
      token: 'delimiter.html',
    },
    {
      foreground: '#11BFF5',
      token: 'tag.html',
    },
  ],
  colors: {
    'editor.background': '#21252b',
  },
};

const DATA_HUB_HOTSPOT_SLUGS = {
  'hotspot-one': { top: 21, left: 20 },
  'hotspot-two': { top: 34.5, left: 30 },
  'hotspot-three': { top: 36, left: 19.5 },
  'hotspot-four': { top: 30, right: 21.64 },
  'hotspot-five': { top: 45, left: 29 },
  'hotspot-six': { top: 30, left: 6 },
};

const METRIC_NAMELIST = [
  {
    id: 'rmse',
    name: 'RMSE',
    tooltipText:
      'The root mean square error (RMSE) is the standard deviation of the residuals. Standard deviation is a measure of how spread out numbers are. Residuals or prediction errors are a measure of how far from the regression line data points are. It is calculated by taking the square root of the average squared difference between the estimated values and actual values.',
  },
  {
    id: 'mae',
    name: 'MAE',
    tooltipText:
      'The mean absolute error (MAE) calculates the average difference between the estimated values and actual values. It is calculated by adding up all the absolute errors and dividing them by the number of errors.',
  },
];

module.exports = {
  PRODUCTION,
  TESTIMONIAL_IMAGE,
  BOARD_IMAGES,
  NEWLY_OPENED_POSITION,
  RECENTLY_OPENED_POSITION,
  OLD_POSITION,
  TRANSITION_CONFIG,
  WIDTH_TAB_BREAKPOINT,
  WIDTH_MOBILE_BREAKPOINT,
  MAIN_BLOG_PATHNAME,
  ICON_COLOR,
  ONLINE_CATALOGUE,
  SPACETIME_URL,
  DEV_PORTAL_URL,
  ENVIRONMENT,
  BLOG_VISIBILITY,
  ONLINE_CATALOGUE_ONLY_ON_ST,
  DEVELOPMENT,
  COLORS,
  TEMP_INT_LIVE_PAGES,
  HERO_HOSTSPOT_SLUGS,
  EMBED_EDITOR_OPTIONS,
  EMBED_CUSTOM_THEME_RULES,
  DATA_HUB_HOTSPOT_SLUGS,
  METRIC_NAMELIST,
};
